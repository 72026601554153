/* frontend/src/components/Home.css */

/* Кастомні стилі для вкладок */
.custom-tabs .nav-tabs {
    border-bottom: 2px solid #dee2e6;
  }
  
  .custom-tabs .nav-link {
    color: #495057;
    font-weight: 500;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  }
  
  .custom-tabs .nav-link:hover {
    background-color: #f8f9fa;
    color: #0d6efd;
  }
  
  .custom-tabs .nav-link.active {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
  }
  
  .custom-tabs .nav-link.active:hover {
    background-color: #0b5ed7;
    border-color: #0a58ca;
  }
  